import "./src/css/style.css"
import "./src/css/reset.css"
import "@fontsource/reenie-beanie"
import "@fontsource/montserrat"
import "@fontsource/nunito-sans/200.css"
import "@fontsource/nunito-sans/400.css"
import "@fontsource/nunito-sans/600.css"
import "./src/css/plyr-3.7.2.css"
import "./src/css/dar.css"
import "./src/css/dar-work.css"

export const registerServiceWorker = () => true